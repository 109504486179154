@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
      url("/public/Poppins/Poppins-Regular.ttf") format("truetype");
  font-weight: normal;
}

body {
  background: #f0f0f0 !important;
}

[type='button'].ms-Button--primary {
    background-color: rgb(0, 120, 212)
}

[type='button'].ms-Button--primary:hover {
    background-color: rgb(16, 110, 190);
    border: 1px solid rgb(16, 110, 190);
    color: rgb(255, 255, 255);
}
::-webkit-scrollbar {
    width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}